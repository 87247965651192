<template lang="pug">
  .new-pupil-wrapper
    h1 Регистрация

    form(@submit.prevent="doRegister")
      .form-group
        input.form-control(type="text"
                           placeholder="Фамилия"
                           v-model="pupil.surname"
                           :class="{ 'is-invalid': $v.pupil.surname.$invalid && $v.pupil.surname.$error }")

        .invalid-feedback Обязательное поле

      .form-group
        input.form-control(type="text"
                           placeholder="Имя"
                           v-model="pupil.name"
                           :class="{ 'is-invalid': $v.pupil.name.$invalid && $v.pupil.name.$error }")

        .invalid-feedback Обязательное поле

      .form-group
        input.form-control(type="text"
                           placeholder="Логин"
                           v-model="pupil.login"
                           :class="{ 'is-invalid': $v.pupil.login.$invalid && $v.pupil.login.$error }")

        .invalid-feedback(v-if="!$v.pupil.login.required") Обязательное поле

      .form-group
        input.form-control(type="password"
                           placeholder="Пароль"
                           v-model="pupil.password"
                           :class="{ 'is-invalid': $v.pupil.password.$invalid && $v.pupil.password.$error }")

        .invalid-feedback(v-if="!$v.pupil.password.required") Обязательное поле
        .invalid-feedback(v-if="!$v.pupil.password.minLength") Минимальная длина пароля 8 символов

      .form-group
        input.form-control(type="password"
                           placeholder="Еще раз пароль"
                           v-model="pupil.password_confirmation"
                           :class="{ 'is-invalid': $v.pupil.password_confirmation.$invalid && $v.pupil.password_confirmation.$error }")

        .invalid-feedback(v-if="!$v.pupil.password_confirmation.sameAs") Пароли не совпадают

      button.btn.btn-outline-success(type="submit") Зарегистрироваться
</template>

<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      pupil: {
        name: '',
        surname: '',
        login: '',
        password: '',
        password_confirmation: ''
      },
      token: ''
    }
  },
  mounted () {
    this.token = this.$route.query.token

    if (!this.token) {
      window.location.href = '/'
    }
  },
  methods: {
    ...mapActions({
      register: 'register'
    }),
    doRegister () {
      this.$v.$touch()

      if (this.$v.$invalid) { return }

      const params = {
        pupil: this.pupil,
        token: this.token
      }

      this.register(params).then((response) => {
        if (response.token) {
          this.$notify({
            title: 'Регистрация',
            type: 'success',
            text: 'Регистрация успешна! Сейчас доделаем пару дел и перезагрузим страницу'
          })

          window.location.href = '/'
        } else {
          this.$notify({
            title: 'Регистрация',
            type: 'error',
            text: 'Что-то пошло не так. Попробуй еще'
          })
        }
      }).catch(() => {
        this.$notify({
          title: 'Регистрация',
          type: 'error',
          text: 'Что-то пошло не так. Попробуй еще'
        })
      })
    }
  },
  validations: {
    pupil: {
      name: { required },
      surname: { required },
      login: { required },
      password: { required, minLength: minLength(8) },
      password_confirmation: { sameAs: sameAs('password') }
    }
  }
}</script>
